<template>
	<section class="domain_content">
		<!-- privacy policy -->
		<div class="sub-page">
			<h1>Privacy Policy</h1>
			<p class="small">Last updated: September 12, 2024</p>
			<p>
				This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal
				 information when using our Service. It also explains your privacy rights and how the law safeguards you.<br>
				We use your personal data to provide and enhance our Service. By using the Service, You consent to the 
				collection and use of Your information as detailed in this Privacy Policy.
			</p>
			<div class="part">
				<h2>
				Collecting and Using Your Personal Data
				</h2>
				<h3>
				Types of Data Collected
				</h3>
				<h5>
				Usage Data
				</h5>
				<p>
					We automatically collect usage data when you interact with the Service. 
					This may include information such as your device’s IP address, browser type 
					and version, the pages you visit on our Service, the time and date of your visit, 
					time spent on pages, unique device identifiers, and diagnostic data. <br>
					When accessing the Service via a mobile device, we may collect additional information, 
					including but not limited to your mobile device type, unique ID, IP address, operating 
					system, and mobile browser type, as well as unique device identifiers and diagnostic data. <br>
					We may also collect information that your browser sends whenever you visit our 
					Service or access it through a mobile device.
				</p>
				<h3>
				Security of Your Personal Data
				</h3>
				<p>
					We prioritize the security of your personal data, but please be aware that no method of 
					transmission over the Internet or electronic storage is completely secure. While we strive 
					to use commercially reasonable means to protect your data, we cannot guarantee its absolute security.
				</p>
				<h2>
				Links to Other Websites
				</h2>
				<p>
					Our Service may contain links to third-party websites that are not operated by us. If you click on one of 
					these links, you will be directed to the respective third party's site. We strongly recommend reviewing the 
					privacy policies of any websites you visit, as we have no control over and assume no responsibility for the 
					content, privacy policies, or practices of third-party sites or services.
				</p>
				<h2>
				Changes to this Privacy Policy
				</h2>
				<p>
				We may update this Privacy Policy from time to time. It is advised to review this page periodically for any updates. 
				Changes to this policy will take effect when posted on this page.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'Our Privacy Policy explains users\' data operating while you download sources or read blogs in this website.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
	},
}
</script>
  